import Vue from "vue";
import VueRouter from "vue-router";

import SetPassword from "../views/SetPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/user/confirm/password",
    name: "set-password",
    component: SetPassword,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

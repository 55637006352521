<template>
  <div
    :style="{
      'background-image': 'url(' + require('../assets/img/bgimg.svg') + ')',
    }"
    class="flex items-center h-screen justify-center w-full"
  >
    <div v-if="showInputs" class="py-4 bg-white rounded-xl shadow-2xl">
      <div class="flex justify-center items-center mt-4">
        <img class="w-48" src="../assets/img/quantumtext.svg" alt="" />
      </div>
      <div class="flex justify-center items-center mt-4">
        <h1>Yeni şifrenizi aşağıdaki alanlardan belirleyebilirsiniz</h1>
      </div>
      <div class="flex items-center flex-wrap justify-center">
        <input
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          class="px-4 w-10/12 py-2 rounded-lg mt-6 border"
          placeholder="Yeni Şifre"
          style="border-color: #ad00ff"
        />
        <div class="relative w-full mt-6 flex items-center justify-center">
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="newPassword"
            class="px-4 w-10/12 py-2 rounded-lg border"
            placeholder="Yeni Şifre Tekrar"
            style="border-color: #ad00ff"
          />
          <img
            @click="togglePasswordVisibility"
            :src="showPasswordIcon"
            alt="Göster"
            class="toggle-password absolute right-10 m-2 cursor-pointer"
            id="show-password"
          />
          <img
            @click="togglePasswordVisibility"
            :src="hidePasswordIcon"
            alt="Gizle"
            class="toggle-password absolute right-0 m-2 cursor-pointer hidden"
            id="hide-password"
          />
        </div>
      </div>

      <div class="mt-6 px-9 my-4">
        <button
          @click="save"
          class="w-full py-2 rounded-lg font-bold text-white hover:opacity-90"
          style="background-color: #ad00ff"
        >
          Kaydet
        </button>
      </div>
    </div>
    <div
      v-else
      class="py-4 bg-white rounded-xl shadow-2xl max-w-md mx-auto p-6"
    >
      <div class="flex justify-center items-center">
        <img
          class="w-48"
          src="../assets/img/quantumtext.svg"
          alt="Quantum Text"
        />
      </div>
      <div class="mt-6 text-center">
        <p class="text-lg font-semibold">Şifreniz başarıyla değiştirildi</p>
        <p class="mt-2 text-gray-700">Sayfayı kapatabilirsiniz.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { changePass } from "@/networking/changePass";

export default {
  data() {
    return {
      password: "",
      newPassword: "",
      showPassword: false,
      showInputs: true,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validatePassword(password) {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
      return passwordRegex.test(password);
    },
    save() {
      if (!this.password || !this.newPassword) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen tüm alanları doldurun.",
          icon: "warning",
          confirmButtonText: "Tamam",
          confirmButtonColor: "#AD00FF",
        });
        return;
      }
      if (!this.validatePassword(this.newPassword)) {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Yeni şifre geçerli bir formatta değil. Şifre en az 8 karakter uzunluğunda olmalı ve en az 1 büyük harf, 1 küçük harf ve 1 rakam içermelidir.",
          confirmButtonText: "Tamam",
          confirmButtonColor: "#AD00FF",
        });
        return;
      }
      if (this.password === this.newPassword) {
        const token = this.$route.query.token;
        Swal.fire({
          title: "Bilgi",
          text: "Şifreniz yenileniyor...",
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        const params = {
          newPassword: this.newPassword,
        };
        changePass(params, token)
          .then(() => {
            Swal.fire({
              title: "Başarılı",
              text: "Şifreniz Yenilendi",
              icon: "success",
              confirmButtonText: "Tamam",
              confirmButtonColor: "#AD00FF",
            });
            this.showInputs = false;
          })
          .catch((e) => {
            console.log(e);
            Swal.fire({
              title: "Hata",
              text: e.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
              confirmButtonColor: "#AD00FF",
            });
          });
      } else {
        Swal.fire({
          title: "Hata",
          text: "Parolalar eşleşmiyor.",
          icon: "error",
          confirmButtonText: "Tamam",
          confirmButtonColor: "#AD00FF",
        });
      }
    },
  },
  computed: {
    showPasswordIcon() {
      return this.showPassword
        ? require("../assets/img/eyenone.svg")
        : require("../assets/img/eye.svg");
    },
    hidePasswordIcon() {
      return this.showPassword
        ? require("../assets/img/eye.svg")
        : require("../assets/img/eyenone.svg");
    },
  },
};
</script>

import axiosClient from "./axiosClient";

export const changePass = async (args, token) => {
  const response = await axiosClient({
    url: `/user/confirm/password?token=${token}`,
    method: "post",
    data: args,
  });
  return response;
};
